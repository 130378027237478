<template>
  <v-navigation-drawer :value="isDrawerOpen" app elevation="4" width="260" class="app-navigation-menu"
    :right="$vuetify.rtl" @input="val => $emit('update:is-drawer-open', val)">
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-3 pb-2 ">
      <router-link to="/" class="d-flex text-center align-center text-decoration-none">
        <v-slide-x-transition>
          <v-img :src="require(`@/assets/images/logos/logo.png`)" max-width="150px" alt="logo" contain eager
            class="app-logo me-3"></v-img>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <div v-if="$store.state.isSavings">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'savings.dashboard' }" icon="gauge"></nav-menu-link>

        <nav-menu-group title="Partnership" icon="hand-holding-hand">
          <nav-menu-link title="Requests" :to="{ name: 'savings.partnership_requests' }"></nav-menu-link>
          <nav-menu-link title="Clients-Partners" :to="{ name: 'savings.partners_clients' }"></nav-menu-link>
        </nav-menu-group>

        <nav-menu-group title="Deposits" icon="circle-dollar-to-slot">
          <nav-menu-link title="Requests" :to="{ name: 'savings.deposit_requests' }"></nav-menu-link>
          <nav-menu-link title="Make a Deposit" :to="{ name: 'savings.make_deposits' }"></nav-menu-link>
          <nav-menu-link title="View Transactions" :to="{ name: 'savings.deposit_transactions' }"></nav-menu-link>
        </nav-menu-group>

        <nav-menu-group title="Withdrawal" icon="money-bill-transfer">
          <nav-menu-link title="Requests" :to="{ name: 'savings.withdrawal_requests' }"></nav-menu-link>
          <nav-menu-link title="Request a Withdrawal" :to="{ name: 'savings.request_withdrawal' }"></nav-menu-link>
          <nav-menu-link title="Make a Withdrawal" :to="{ name: 'savings.make_withdrawals' }"></nav-menu-link>
          <nav-menu-link title="View Transactions" :to="{ name: 'savings.withdrawal_transactions' }"></nav-menu-link>
        </nav-menu-group>

        <nav-menu-group title="Interrupt" icon="ban">
          <nav-menu-link title="Requests" :to="{ name: 'savings.interrupt_requests' }"></nav-menu-link>
          <nav-menu-link title="Make an Interrupt" :to="{ name: 'savings.make_interrupt' }"></nav-menu-link>
          <nav-menu-link title="View Transactions" :to="{ name: 'savings.interrupt_transactions' }"></nav-menu-link>
        </nav-menu-group>

        <nav-menu-group title="Clients" icon="users">
          <nav-menu-link title="Summary" :to="{ name: 'savings.clients' }"></nav-menu-link>
          <nav-menu-link title="Create Client" :to="{ name: 'savings.create_client' }"></nav-menu-link>
          <nav-menu-link title="View Zones" :to="{ name: 'savings.clients_zones' }"></nav-menu-link>
          <nav-menu-link title="Create Zone" :to="{ name: 'savings.clients_create_zone' }"></nav-menu-link>
          <nav-menu-link title="Campaigns" :to="{ name: 'savings.clients_campaigns' }"></nav-menu-link>
          <nav-menu-link title="Create Campaign" :to="{ name: 'savings.clients_create_campaign' }"></nav-menu-link>
          <nav-menu-link title="Loans" :to="{ name: 'savings.clients_loans' }"></nav-menu-link>
          <nav-menu-link title="Create Loan" :to="{ name: 'savings.clients_create_loan' }"></nav-menu-link>
        </nav-menu-group>

        <nav-menu-group title="Top Ups" icon="users-gear">
          <nav-menu-link title="Summary" :to="{ name: 'savings.topup_summary' }"></nav-menu-link>
          <nav-menu-link title="Create Topup" :to="{ name: 'savings.topup_create' }"></nav-menu-link>
          <nav-menu-link title="Reset Password" :to="{ name: 'savings.topup_requested_password' }"></nav-menu-link>
          <nav-menu-link title="Recharge" :to="{ name: 'savings.topup_recharge' }"></nav-menu-link>
          <nav-menu-link title="Transactions" :to="{ name: 'savings.topup_transactions' }"></nav-menu-link>
          <nav-menu-link title="Faults" :to="{ name: 'savings.topup_faults' }"></nav-menu-link>
        </nav-menu-group>

        <nav-menu-group title="Cash Flow" icon="bank">
          <nav-menu-link title="Profits" :to="{ name: 'savings.profits_commisions' }" icon="money-bill-trend-up"></nav-menu-link>
          <nav-menu-link title="Investments" :to="{ name: 'savings.investments' }" icon="business-time"></nav-menu-link>
          <nav-menu-link title="Employee's Salaries" :to="{ name: 'savings.salaries' }" icon="money-check-dollar"></nav-menu-link>
          <nav-menu-link title="Salary Payment" :to="{ name: 'savings.employees_payments' }" icon="hand-holding-dollar"></nav-menu-link>
          <nav-menu-link title="Bills" :to="{ name: 'savings.bills' }" icon="file-invoice"></nav-menu-link>
        </nav-menu-group>
        
        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'savings.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'savings.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>

        <nav-menu-link title="Contact Us" :to="{ name: 'savings.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>

    <div v-if="$store.state.isMarketPlace">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'marketplace.dashboard' }" icon="gauge"></nav-menu-link>
        <nav-menu-link title="Products" :to="{ name: 'marketplace.products' }" icon="cart-plus"></nav-menu-link>
        <nav-menu-link title="Requested Orders" :to="{ name: 'marketplace.orders' }" icon="shopping-basket"></nav-menu-link>
        <nav-menu-link title="Deliveries" :to="{ name: 'marketplace.transactions' }" icon="shopping-cart"></nav-menu-link>
        <nav-menu-link title="Faults" :to="{ name: 'marketplace.faults' }" icon="file-invoice-dollar"></nav-menu-link>
        <nav-menu-link title="Profits" :to="{ name: 'marketplace.profits_commisions' }" icon="money-bill-trend-up"></nav-menu-link>
        <!-- <nav-menu-link title="Bills" :to="{ name: 'marketplace.bills' }" icon="file-invoice-dollar"></nav-menu-link> -->

        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'marketplace.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'marketplace.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Contact Us" :to="{ name: 'marketplace.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>

    <div v-if="$store.state.isNjangi">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'njangi.dashboard' }" icon="gauge"></nav-menu-link>

        <nav-menu-group title="Groups" icon="users">
          <nav-menu-link title="Join Requests" :to="{ name: 'njangi.join_requests' }"></nav-menu-link>
          <nav-menu-link title="Add New Njangi Group" :to="{ name: 'njangi.create_group' }"></nav-menu-link>
          <nav-menu-link title="Accept User To Njangi" :to="{ name: 'njangi.accept_requests' }"></nav-menu-link>
          <nav-menu-link title="View Groups" :to="{ name: 'njangi.view_groups' }"></nav-menu-link>
        </nav-menu-group>

        <nav-menu-group title="Deposits" icon="circle-dollar-to-slot">
          <nav-menu-link title="Requests" :to="{ name: 'njangi.contribution_requests' }"></nav-menu-link>
          <nav-menu-link title="Make a Contribution" :to="{ name: 'njangi.make_contribution' }"></nav-menu-link>
          <nav-menu-link title="View Transactions" :to="{ name: 'njangi.contribution_transactions' }"></nav-menu-link>
        </nav-menu-group>
        
        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'njangi.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'njangi.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Contact Us" :to="{ name: 'njangi.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>

    <div v-if="$store.state.isFundraising">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'njangi.dashboard' }" icon="gauge"></nav-menu-link>
        <nav-menu-link title="Projects" :to="{ name: 'njangi.njangilist' }" icon="project-diagram"></nav-menu-link>
        <nav-menu-link title="Events" :to="{ name: 'njangi.eventlist' }" icon="calendar-alt"></nav-menu-link>
        <nav-menu-link title="Transactions" :to="{ name: 'njangi.transactions' }" icon="money-bill-transfer"></nav-menu-link>
        <nav-menu-link title="My Faults" :to="{ name: 'njangi.my_faults' }" icon="file-invoice-dollar"></nav-menu-link>
        <nav-menu-link title="Deposits" :to="{ name: 'njangi.deposits' }" icon="circle-dollar-to-slot"></nav-menu-link>
        <nav-menu-link title="Withdrawals" :to="{ name: 'njangi.requested_withdrawals' }" icon="cash-register"></nav-menu-link>

        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'njangi.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'njangi.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Contact Us" :to="{ name: 'njangi.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>

    <div v-if="$store.state.isMonitor">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'njangi.dashboard' }" icon="gauge"></nav-menu-link>
        <nav-menu-link title="Users" :to="{ name: 'njangi.deposits' }" icon="users"></nav-menu-link>
        <nav-menu-link title="My Follow Ups" :to="{ name: 'njangi.requested_withdrawals' }" icon="money-bill-transfer"></nav-menu-link>

        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'njangi.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'njangi.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Contact Us" :to="{ name: 'njangi.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import NavMenuLink from './components/NavMenuLink.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import axios from 'axios'
import store from "../../../store";
import { computed, onMounted } from '@vue/composition-api';

export default {
  components: {
    NavMenuLink,
    NavMenuGroup,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      mini: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

/* ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)*/
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
